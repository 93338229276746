import React, { Component } from 'react';

const ModalContext = React.createContext();

export class ModalProvider extends Component {
    state = {
        isModalOpen: false,
        almacen: 0
    }

    ModalOpen = (alm) => {
        this.setState({ isModalOpen: true, almacen: alm })
    }

    ModalClose = () => {
        this.setState({ isModalOpen: false })
    }

    render() {
        const { isModalOpen } = this.state;
        const { almacen } = this.state;
        const { ModalOpen, ModalClose } = this;
        return (
            <ModalContext.Provider value={{
                isModalOpen,
                almacen,
                ModalOpen,
                ModalClose
            }}>
                {this.props.children}
            </ModalContext.Provider>
        )
    }
}

export default ModalContext;