import React, { Component } from 'react';
import './css/login.css';

class Login extends Component {

    usuarioRef = React.createRef();
    passwordRef = React.createRef();

    loguear = (e) => {
        e.preventDefault();

        //obtengo dato de input
        const usuario = this.usuarioRef.current.value;
        const password = this.passwordRef.current.value;

        //lo paso a metodo global
        this.props.datosLogin(usuario, password);
    }

    render(){
        return ( 
            <div id="login">
            <div className= "cont2">

                <div className="left2"> 
                    <br/>  
                    <br/>  
                    <img className="ionred" src="https://tiendavenus.com.mx/filerun/files_pau/logoanc/logoanc%20negro.png" alt="logo ionica"/>
                    <br/> 
                    <div className="msj-error">{this.props.mensaje}</div>
                    <br/> 
                    <form onSubmit={this.loguear}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="material-icons">person_outline</i>
                                </span>
                            </div>
                            <input ref={this.usuarioRef} type="text" className="form-control" placeholder="Usuario" required="required"/>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className='material-icons'>lock_outline</i>
                                </span>
                            </div>
                            <input ref={this.passwordRef} type="password" className="form-control" placeholder="Contraseña" required="required"/>
                        </div>
                        <button type="submit" className="btn">ACCEDER</button>
                    </form> 
                </div>
    
                <div className="right2">  
                    <div className="right-cont"> 
                        <br/> 
                        <br/> 
                        <h3>"Conviértete en la persona que atraiga los resultados que buscas."</h3> 
                        <i>Jim Cathcart</i>
                    </div> 
                </div>
		    </div>
            </div>
        );
    }
}

export default Login;