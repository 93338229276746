import React, { Component } from 'react';
import './css/home.css';
import { Alert, Modal, Spin } from 'antd';
/* import Modal from './Modal'; */
import Asistencia from './Asistencia';
/* import Ventas from './Ventas'; */
import ModalSolicitud from './ModalSolicitud';

class Home extends Component {
    fileSolicitudRef = React.createRef();
    state = {
        empleados: [],
        horas: '',
        minutos: '',
        segundos: '',
        dia: '',
        mes: '',
        anio: '',
        mt: 'AM',
        modal_open: false,
        productos: [],
        busqueda: '',
        loading: false,
        display_id: 1,
        empleados_ventas: [],
        password: '',
        empleado_seleccionado: '',
        mostrarModalSolicitud: false,
        manuales: []
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    componentDidMount() {
        if (this.state.horas === '') {
            const url = this.props.url_api + '/get_init_data.php?almacen=' + this.props.id_almacen + '&token=' + Math.random();
            fetch(url, { cache: "no-store" })
                .then(respuesta => respuesta.json())
                .then(resultado => {
                    this.procesarDatos(resultado.empleados,
                        resultado.hora, resultado.empleados_ventas)

                });
            this.timerID = setInterval(() => this.getHora(), 1000);

            //Obtener manuales
            fetch("https://apicheckin.tiendavenus.com.mx/manuales/getAll/index.php?token=" + Math.random(), {
                method: 'GET'
            }).then(res => res.json())
                .then(data => {
                    this.setState({
                        manuales: data
                    })
                })
        }
    }

    procesarDatos = (empleados, date, empleados_ventas) => {
        var fechacompleta = new Date(date);
        var horas = fechacompleta.getHours();
        var minutos = fechacompleta.getMinutes();
        var segundos = fechacompleta.getSeconds();
        var dia = fechacompleta.getDate();
        var mes = fechacompleta.getMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
        var anio = fechacompleta.getFullYear();
        this.setState({
            empleados,
            horas,
            minutos,
            segundos,
            dia,
            mes,
            anio,
            empleados_ventas
        })
    }

    getHora() {
        var horas = this.state.horas;
        var minutos = this.state.minutos;
        var segundos = this.state.segundos;
        var mt = this.state.mt;
        segundos++;

        if (segundos >= 60) {
            minutos++;
            segundos = 0;
        }
        if (minutos >= 60) {
            horas++;
            minutos = 0;
        }
        if (horas >= 24) {
            horas = 0;
        }

        var horas1 = horas;
        if (horas > 12) {
            mt = "PM";
            var horas1 = horas - 12;
        }
        var minutos1 = minutos;
        var segundos1 = segundos;
        if (horas1 === 0) var horas1 = 12;
        this.setState({
            horas: horas1,
            minutos: minutos1,
            segundos: segundos1,
            mt
        });

    }

    /* mostrarProductos = () => {
        if (!this.state.loading) {
            const productos = this.state.productos;
            if (productos.length !== 0) {
                return (
                    <React.Fragment>
                        {productos.map(asis => (
                            <div id="producto" className="" key={asis.id}>
                                <div className="img-container">
                                    <img src={asis.imagen} alt="" />
                                </div>
                                <div className="datos">
                                    <h3>{asis.nombre}</h3>
                                    <h1 className="hora">{asis.referencia}</h1>
                                    <p>{asis.descripcion}</p>

                                </div>
                                <div className={asis.cantidad === '0' ? "precio no-link" : "precio"}>
                                    <span>${asis.precio}</span>
                                    {asis.cantidad !== '0' ? <a href={asis.link} target="_blank">
                                        <i class="material-icons">link</i>
                                    </a> : null}
                                </div>

                            </div>
                        ))}
                    </React.Fragment>
                );
            } else {
                return (
                    <Alert
                        message="No se ha encontrado productos"
                        description="Por favor introduzca o corrija el parametro de busqueda"
                        type="info"
                        showIcon
                    />
                );
            }
        } else {
            return (
                <Spin spinning={this.state.loading} style={{ width: '100%', textAlign: 'center' }} />
            );
        }
    } */

    /* changeBusqValue = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        })

        const data = this.fileSolicitudRef.current.value;

        const url = 'https://tiendavenus.com/api0519/buscador.php?data=' + data;
        fetch(url, { cache: "no-store" })
            .then(respuesta => respuesta.json())
            .then(resultado => this.setState({ productos: resultado, loading: false }));
    } */

    openSolicitudModal = () => {

        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year

        var fecha = year + '-' + month + '-' + date;

        const url = this.props.url_api + '/get_asistenciaUser.php?user=' + this.state.empleado_seleccionado + '&fecha=' + fecha + '&password=' + this.state.password;

        fetch(url, { cache: "no-store" })
            .then(respuesta => respuesta.json())
            .then(resultado => {
                if (resultado.clase === "error") {
                    alert('Contraseña incorrecta!')
                }
                else {
                    this.setState({
                        mostrarModalSolicitud: true
                    })
                }
            });
    }

    /* submitSolicitudMercancia = () => {

        //console.log(this.state)

        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year

        var fecha = year + '-' + month + '-' + date;

        const url = this.props.url_api + '/get_asistenciaUser.php?user=' + this.state.empleado_seleccionado + '&fecha=' + fecha + '&password=' + this.state.password;

        fetch(url, { cache: "no-store" })
            .then(respuesta => respuesta.json())
            .then(resultado => {
                if (resultado.clase === "error") {
                    return alert('Contraseña incorrecta!')
                }
            });

        const file = this.fileSolicitudRef.current.files[0];

        const idAlmacen = this.props.nombre_almacen;

        const fechaHora = new Date();
        const dia = fechaHora.getDate().toString().padStart(2, '0');
        const mes = (fechaHora.getMonth() + 1).toString().padStart(2, '0');
        const anio = fechaHora.getFullYear().toString();
        const hora = fechaHora.getHours().toString().padStart(2, '0');
        const minutos = fechaHora.getMinutes().toString().padStart(2, '0');

        const nombreDeseado = `${idAlmacen.replace(/ /g, '_')}_${dia}-${mes}-${anio}-${hora}h${minutos}m`; // Formar el nombre deseado

        fetch('https://apiwms.tiendavenus.com.mx/solicitudes/crear/index.php', {
            method: 'POST',
            body: JSON.stringify({
                "creada_por": this.state.empleado_seleccionado,
                "sucursal": `${this.props.nombre_almacen}`,
                "archivo": `${nombreDeseado}.${file.name.split('.').pop()}`
            })
        }).then(data => data.json())
            .then(res => {
                if (res.id === 1) {

                    const formData = new FormData();
                    formData.append('archivo', file, `${nombreDeseado}.${file.name.split('.').pop()}`); // Agregar el archivo con el nombre deseado al FormData

                    // Realizar la solicitud al servidor para subir el archivo
                    fetch('https://apiwms.tiendavenus.com.mx/solicitudes/uploadFile/index.php', {
                        method: 'POST',
                        body: formData
                    }).then(_ => {
                        alert("Solicitud enviada exitosamente.");
                        window.location.href = "/";
                    })
                }
                else return alert("Ha ocurrido un error al enviar la solicitud. Por favor contacta a tu gerente.");
            })
    } */

    renderContent = (id) => {
        if (id === 1) {
            return (
                this.state.dia !== '' ?
                    <Asistencia
                        empleados={this.state.empleados}
                        url_api={this.props.url_api}
                        almacen={this.props.id_almacen}
                        modal_open={this.state.modal_open}
                        horas={this.state.horas}
                        minutos={this.state.minutos}
                        segundos={this.state.segundos}
                        dia={this.state.dia}
                        mes={this.state.mes}
                        anio={this.state.anio}
                        mt={this.state.mt}
                    />
                    :
                    null
            );
        }
        if (id === 2) {
            return (
                <div className={"col-sm-12 search_panel"}>
                    {/* <form>
                        <div className="head_search">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className='material-icons'>description</i>
                                    </span>
                                </div>
                                <input style={{ paddingTop: '12px' }} ref={this.fileSolicitudRef} type="file" accept=".xslx, .csv" className="form-control" placeholder="Sube tu archivo de Excel con tu solicitud" required />
                            </div>
                        </div>
                    </form> */}
                    <p style={{
                        textAlign: 'center',
                        /* paddingTop: '90px', */
                        fontSize: '14px',
                        fonWeight: 'bolder'
                    }}>Escribe tus datos y haz click en el botón "Crear solicitud" para comenzar. Si necesitas ayuda, consulta <a href="">la guía haciendo click aquí.</a></p>
                    <div className="container" style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <div className="row" style={{
                            flexDirection: 'column',
                            width: '500px',
                            flexWrap: 'nowrap',
                            gap: '12px',
                            marginTop: '20px'
                        }}>
                            <select onChange={(e) => this.setState({
                                empleado_seleccionado: e.target.value
                            })} className='form-control'>
                                <option value="" selected>Elige tu nombre...</option>
                                {this.state.empleados.map((e) => (
                                    <option key={e.id} value={e.id}>{e.nombre + " " + e.apellido}</option>
                                ))}
                            </select>
                            <input onChange={(e) => this.setState({
                                password: e.target.value
                            })} className='form-control' type="password" placeholder='Escribe tu contraseña' />
                            <button style={{
                            }} className='btn btn-sm bg-info p-2' onClick={() => {
                                /* this.submitSolicitudMercancia() */
                                this.openSolicitudModal()
                            }}>Crear solicitud</button>
                        </div>
                    </div>
                </div>
            );
        }
        if (id === 3) {
            return (
                <div className={"col-sm-12 search_panel"}>
                    <p style={{
                        textAlign: 'center',
                        /* paddingTop: '90px', */
                        fontSize: '24px',
                    }}><b>Guías y Manuales</b></p>
                    <div className="container" style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <div className="row" style={{
                            flexDirection: 'column',
                            width: '800px',
                            flexWrap: 'nowrap',
                            gap: '12px',
                            marginTop: '20px'
                        }}>
                            {this.state.manuales.map((e, key) => (
                                <div className="item-manuales" key={key}>
                                    <div className="nombre">{e.descripcion}</div>
                                    <div className="boton-cta" onClick={() => window.open(e.url, "_blank")}>Abrir</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            );
        }
    }

    handleMostrarModal = () => {
        this.setState({ mostrarModalSolicitud: !this.state.mostrarModalSolicitud })
    }


    render() {
        return (
            <div id="todo">
                {/* <Modal/> */}
                <div style={{ position: 'absolute', top: '75px', left: '38px', fontSize: '11px' }}>
                    Versión 3-19/08/24
                </div>
                {this.state.mostrarModalSolicitud ? <ModalSolicitud mostrarModalSolicitud={() => this.handleMostrarModal()} empleados={this.state.empleados} almacen={this.props.nombre_almacen} empleado={this.state.empleado_seleccionado} /> : null}

                <header className="row">
                    <div className="logo col-sm-2">
                        <img src="https://tiendavenus.com.mx/filerun/files_pau/logoanc/logoanc%203.png" alt="" height="30px" />
                    </div>
                    <div className="name col-sm-3">
                        <span>Almacén</span>
                        <p>{this.props.nombre_almacen}</p>

                    </div>
                    <div className="hora col-sm-2">
                        <div>
                            <span>Hora actual:</span>
                            <p>{this.state.horas < 10 ? "0" + this.state.horas : this.state.horas}:{this.state.minutos < 10 ? "0" + this.state.minutos : this.state.minutos}:{this.state.segundos < 10 ? "0" + this.state.segundos : this.state.segundos} {this.state.mt}</p>
                        </div>
                    </div>
                    <div className="btn_container col-sm-5">
                        <button className={this.state.display_id === 1 && 'active'} onClick={() => this.setState({ display_id: 1 })}><i class="material-icons">done</i><b>Checkin</b></button>
                        <button className={this.state.display_id === 3 && 'active'} onClick={() => this.setState({ display_id: 3 })}><i class="material-icons">library_books</i><b>Manuales</b></button>
                        {/* <button className={this.state.display_id === 2 && 'active'} onClick={() => this.setState({ display_id: 2 })}><i class="material-icons">search</i><b>Buscador</b></button>
                        <button className={this.state.display_id === 3 && 'active'} onClick={() => this.setState({ display_id: 3 })}><i class="material-icons">shopping_cart</i><b>Venta</b></button> */}
                        {/* <button className={this.state.display_id === 2 && 'active'} onClick={() => this.setState({ display_id: 2 })}><i class="material-icons">insert_drive_file</i><b>Solicitud de mercancía</b></button> */}
                        <div className="logout">
                            <button onClick={() => this.props.logOut()}><i class="material-icons">exit_to_app</i></button>
                        </div>
                    </div>
                </header>
                <div id="contenido" className="row">
                    {this.renderContent(this.state.display_id)}
                </div>
            </div>
        );
    }
}

export default Home;