import React, { Component } from 'react';
import Login from './componentes/Login';
import Home from './componentes/Home';
import { message } from 'antd';
import 'antd/dist/antd.css';
import './App.css';
import ModalContext, { ModalProvider } from './Context/ModalContext';

class App extends Component {

  state = {
      url_api : 'https://tiendavenus.com.mx/07980519/api',
      id_almacen : '',
      mensaje_login : '',
      nombre_almacen: '',
      comida: false
  }

  isLogUser = () => { 
    if(this.state.id_almacen === '') return (
      <Login 
        datosLogin={this.datosLogin} 
        mensaje={this.state.mensaje_login}
      />
    );
    else return(
      <Home 
        logOut={this.logOut}
        id_almacen={this.state.id_almacen}
        nombre_almacen={this.state.nombre_almacen}
        comida={this.state.comida}
        url_api={this.state.url_api}
      />
    );
  }

  logOut = () => {
    localStorage.removeItem('token');
    this.setState({
      id_almacen : '',
      mensaje_login : '',
      nombre_almacen: '',
      comida: false
    });
  }

  datosLogin = (usuario, password) => {
    const url = this.state.url_api+'/login.php?user='+usuario+'&password='+password;
    message.loading('Ingresando..', 0.2);
      fetch(url)
          .then(respuesta => respuesta.json())
          .then(resultado => this.setLocaldata(resultado.id,resultado.mensaje,resultado.exito));
        console.log(url);
  }

  setLocaldata = (token, mensaje, id) => {
    //comprobar que el id es de un inicio de sesion exitoso
    if(id === 1 || id ==='1'){
      localStorage.setItem('almacen', token);
      console.log(token);
      this.validarToken(token);
      
    }else{
      message.error(mensaje);
    }
  }

  validarToken = (token) => {
    const url = this.state.url_api+'/verify.php?token='+token;

      fetch(url)
          .then(respuesta => respuesta.json())
          .then(resultado => this.setState({
            id_almacen: resultado.id,
            comida: resultado.comida,
            nombre_almacen: resultado.nombre
          }));
  }

  componentDidMount(){
    if(this.state.id_almacen === ''){
      if(localStorage.getItem('almacen')){
        var token_ls = localStorage.getItem('almacen');
        this.validarToken(token_ls);
      }
    }
  }

  render(){
      return (
        <ModalProvider>
          <div>
            {this.isLogUser()}
          </div>
        </ModalProvider>
      );
  }
}

export default App;
