import React from 'react'
import './css/modalSolicitud.css'
import surtido from './../surtido.json'
import { useState } from 'react'

const ModalSolicitud = ({ mostrarModalSolicitud, almacen, empleado, empleados }) => {

    const [productosExtra, setProductosExtra] = useState([])
    const [SKUproductoExtra, setSKUProductoExtra] = useState("")
    const [cantidadProductoExtra, setCantidadProductoExtra] = useState(1)

    const handleAddProductoExtra = () => {

        // Verifica si el productoNuevo ya existe en el surtido
        let productoExistente = false;

        for (const producto of surtido) {
            if (producto.clave === SKUproductoExtra.toUpperCase()) {
                productoExistente = true;
                break; // Termina el bucle tan pronto como encuentre un producto con la misma clave
            }
        }

        if (productoExistente) {
            return alert('El producto ya está incluído en el pedido sugerido. No se agregará.');
        }

        if (SKUproductoExtra === "") return; //Verificar que no se envíe un sku vacío

        if (productosExtra.some((e) => e.clave === SKUproductoExtra)) return; //Verificar si la clave ya está en el array de productos extra

        setProductosExtra([...productosExtra,
        {
            "clave": SKUproductoExtra,
            "descripcion": "",
            "actual": 0,
            "cedis": 0,
            "promedio_x_semana": 0,
            "ultima_entrada": "",
            "ultima_venta": "",
            "sugerido": 0,
            "sugerido_adicional": cantidadProductoExtra
        }
        ])

        //Reset de los valores
        setCantidadProductoExtra(1)
        setSKUProductoExtra("")
    }

    const handleCantidadProductoExtra = (type) => {
        if (type === 'up') {
            setCantidadProductoExtra(cantidadProductoExtra + 1)
        }
        else if (type === 'down') {
            setCantidadProductoExtra(cantidadProductoExtra > 1 ? cantidadProductoExtra - 1 : 1)
        }
    }

    const deleteProductoExtra = (clave) => {
        const nuevoArray = productosExtra.filter((objeto) => objeto.clave !== clave);
        setProductosExtra(nuevoArray);
    }

    const handleEnviarSolicitud = () => {
        //Obtener datos del empleado
        const datosEmpleado = empleados.filter(e => e.id === parseInt(empleado))

        //Combinar arrays algoritmo + agregados por empleado
        const data = [...surtido, ...productosExtra];
        const jsonData = JSON.stringify(data);

        // Codificar la cadena en UTF-8
        const encoder = new TextEncoder();
        const utf8Data = encoder.encode(jsonData);

        // Convertir a base64
        const base64Data = btoa(String.fromCharCode.apply(null, utf8Data));

        fetch('https://apiwms.tiendavenus.com.mx/solicitudes/createFile/index.php', {
            method: 'POST',
            body: JSON.stringify({
                "base64": base64Data,
                "idAlmacen": almacen
            })
        })
            .then(res => res.json())
            .then(data => {
                fetch('https://apiwms.tiendavenus.com.mx/solicitudes/crear/index.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        "creada_por": datosEmpleado[0].nombre + " " + datosEmpleado[0].apellido,
                        "sucursal": almacen,
                        "archivo": data.archivo
                    })
                }).then(data => data.json())
                    .then(res => {
                        if (res.id === 1) {
                            alert("Se ha enviado la solicitud exitosamente.");
                        }
                        else
                            alert("Ha ocurrido un error, por favor contacta a tu gerente.");
                    })
            })
    }

    return (
        <div className="modal-solicitud-container">
            <div className="modal-solicitud-box">
                <div className="items-container">
                    <div className="cerrar">
                        <span onClick={() => mostrarModalSolicitud()}>Salir sin enviar solicitud</span>
                    </div>
                    <div className="item titulos" style={{ justifyContent: 'space-between' }}>
                        {/* <div className="value imagen"><b>Imagen</b></div> */}
                        <div className="value sku"><b>SKU</b></div>
                        <div className="value nombre"><b>Nombre</b></div>
                        <div className="value box">
                            <div><b>Piezas</b></div>
                        </div>
                    </div>
                    <h5 style={{ margin: '24px 0' }}>Pedido sugerido <small>(Los siguientes productos se agregarán de manera automática a tu solicitud).</small></h5>
                    {surtido.slice(0, 15).map((e, key) => (
                        <div className="item" key={key}>
                            {/* <div className="value imagen">
                            <img src="http://haresfiles.s3.us-east-1.amazonaws.com/imgs/R1313_1.webp" />
                        </div> */}
                            <div className="value sku">{e.clave}</div>
                            <div className="value nombre">{e.descripcion}</div>
                            <div className="value box">
                                {/* <div className="down">-</div> */}
                                <div className="sugerida">{e.sugerido + e.sugerido_adicional}</div>
                                {/* <div className="up">+</div> */}
                            </div>
                        </div>
                    ))}
                    <h5 style={{ margin: '24px 0' }}>Productos extra <small>(Agregados por ti).</small></h5>
                    {productosExtra.map((e, key) => (
                        <div className="item" key={key}>
                            {/* <div className="value imagen">
                            <img src="http://haresfiles.s3.us-east-1.amazonaws.com/imgs/R1313_1.webp" />
                        </div> */}
                            <div className="value sku">{e.clave}</div>
                            <div className="value box">
                                <div className="sugerida">{e.sugerido_adicional} pieza(s)</div>
                            </div>
                            <div className="down" onClick={() => deleteProductoExtra(e.clave)}><b>Quitar</b></div>
                        </div>
                    ))}
                    <div className="item agregar" style={{ justifyContent: 'space-around' }}>
                        <div className="value sku"><b><input type="text" value={SKUproductoExtra} onChange={(e) => setSKUProductoExtra(e.target.value)} placeholder="SKU" id="skuExtra" name="skuExtra" /></b></div>
                        <div className="value nombre">Escribe un SKU en el recuadro de la izquierda<br />para agregar otro producto a tu solicitud.</div>
                        <div className="value box">
                            <div className="value box">
                                <div className="down" onClick={() => handleCantidadProductoExtra('down')}>-</div>
                                <div className="sugerida">{cantidadProductoExtra}</div>
                                <div className="up" onClick={() => handleCantidadProductoExtra('up')}>+</div>
                            </div>
                        </div>
                        <div className="value agregar">
                            <button onClick={() => handleAddProductoExtra()}>Agregar</button>
                        </div>
                    </div>
                    <center><button className='enviar-solicitud' onClick={() => handleEnviarSolicitud()}>Enviar solicitud</button></center>
                </div>
            </div>
        </div>
    )
}

export default ModalSolicitud